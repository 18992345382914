import * as React from "react";
import {
  Input,
  Loader,
  QuestionCircleIcon,
  FormMessage,
  Tooltip,
} from "@fluentui/react-northstar";

interface TokenInputProps {
  token?: string;
  loading: boolean;
  disabled: boolean;
  valid: boolean;
  error: null|string;
  onChangeFn: any;
}

const TokenInput = (props: TokenInputProps) => {
  const { token, loading, disabled, valid, error, onChangeFn } = props;

  const onchange = (_: any, input: any) => {
    onChangeFn(input.value);
  };

  return (
    <div>
      <Input
        fluid
        disabled={disabled}
        label="Code to connect to Meltwater"
        placeholder="Meltwater code"
        error={!!error}
        showSuccessIndicator={valid}
        onChange={onchange}
        value={token}
        maxLength={36}
        styles={{ width: "320px" }}
      />
      <Tooltip content={"Copy this code from Meltwater app > Accounts > Integrations & API"} trigger={
        <QuestionCircleIcon
          style={{ marginTop: "10px", marginLeft: "5px" }}
          size="large"
          disabled
        />} 
      />
      {loading && (
        <Loader
          style={{ verticalAlign: "bottom", padding: "5px" }}
          size="smallest"
          inline
        />
      )}
      <div>
        <FormMessage
          content={
            error
          }
          error
          role='error'
        />
      </div>
    </div>
  );
};

export default TokenInput;
