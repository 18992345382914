import { Provider } from "@fluentui/react-northstar";
import { useState, useEffect, useCallback } from "react";
import { useTeams } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";
import TokenInput from "./TokenInput";

const { REACT_APP_BACKEND_BASE_URL, REACT_APP_PUBLIC_HOSTNAME } = process.env;

interface ExtendedConnectorSettings extends microsoftTeams.settings.Settings {
  webhookUrl?: string;
  userObjectId?: string;
  configName?: string;
}

interface SplitEntityValue {
  token:string, 
  companyId?: string
}

export const splitEntityId = (entityId: string):SplitEntityValue => {
  const [token, companyId] = entityId.split('#')
  return {token, companyId}
}

export const createEntityId = (token: string, companyId: string):string => {
  return token + '#' + companyId
}

export const saveConfig = (context: microsoftTeams.Context, setting: ExtendedConnectorSettings):Promise<null> => {
  if(typeof setting.entityId !== 'string'){
    throw new Error('Error: No entityId on connector settings')
  }
  const { token } = splitEntityId(setting.entityId)
  return fetch(`${REACT_APP_BACKEND_BASE_URL}/v1/teams/token/config/save`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      webhookUrl: setting.webhookUrl,
      token,
      user: setting.userObjectId,
      teamId: context.teamId,
      teamName: context.teamName,
      channelId: context.channelId,
      channelName: context.channelName,
    }),
  }).then((response) => {
    if (response.status !== 200)
      throw new Error(`${response.status}: ${response.statusText}`);

    return response.json();
  });
};

export const deleteConfig = (context: microsoftTeams.Context, setting: ExtendedConnectorSettings):Promise<null> => {
  return fetch(`${REACT_APP_BACKEND_BASE_URL}/v1/teams/token/config/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      entityId: setting.entityId,
      teamId: context.teamId,
      channelId: context.channelId,
    }),
  }).then((response) => {
    if (response.status !== 200)
      throw new Error(`${response.status}: ${response.statusText}`);

    return response.json();
  });
};

interface ValidateTokenResponse {
  isTokenValid: boolean;
  companyId: string;
  configAlreadyExists: boolean;
}

export const validateToken = (value: string, context: microsoftTeams.Context):Promise<ValidateTokenResponse> => {
  return fetch(`${REACT_APP_BACKEND_BASE_URL}/v1/teams/token/config/validate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: value,
      teamId: context.teamId,
      channelId: context.channelId,
    }),
  })
    .then((response) => {
      if (response.status !== 200)
        throw new Error(`${response.status}: ${response.statusText}`);
      return response.json();
    })
};

interface IConfigState {
  disabled: boolean;
  loading: boolean;
  error: null|string;
  valid: boolean;
}

const initalConfigState = {
  disabled: false,
  loading: false,
  error: null,
  valid: false,
}

function MeltwaterTeamsConnectorConfig() {
  // External Libraries
  const [{theme, context}] = useTeams();

  // State Hooks
  const [configState, setConfigState] = useState<IConfigState>(initalConfigState);
  const [tokenValue, setTokenValue] = useState<string>();
  const [companyId, setCompanyId] = useState<string>();

  // Set Config State Actions
  const setConfigState_Inital = useCallback(() => setConfigState(initalConfigState), [])
  const setConfigState_Loading = useCallback(() => setConfigState({
    ...initalConfigState,
    loading: true
  }), [])
  const setConfigState_Valid = useCallback(() => setConfigState({
    ...initalConfigState,
    valid: true
  }), [])
  const setConfigState_Error = useCallback((msg?:string) => setConfigState({
    ...initalConfigState,
    error: msg || 'Error: An unexpected error occured'
  }), [])
  const setConfigState_Disabled = useCallback(() => setConfigState({
    ...initalConfigState,
    valid: true,
    disabled:true
  }), [])

  // On Input Change Function
  const onChangeFn = (value: string) => {
    setTokenValue(value);
    microsoftTeams.settings.setValidityState(false);
    setConfigState_Inital()
    if (value.length === 36) {
      if(context){
        setConfigState_Loading();
        validateToken(value, context)
          .then(({isTokenValid, companyId, configAlreadyExists}) => {
            if(!isTokenValid){
              setConfigState_Error('Error: Token is invalid')
            } else if (configAlreadyExists){
              setConfigState_Error('Error: You are already connected to this channel.  Delete your old connecting, or try connecting to a different channel')
            } else if (!companyId){
              setConfigState_Error('Error: Could not retrieve your companyId')
            } else {
              microsoftTeams.settings.setValidityState(isTokenValid);
              setConfigState_Valid()
              setCompanyId(companyId);
            }
          })
          .catch((err) => {
            console.log(err);
            setConfigState_Error('Error: There was a problem validating your token')
          });
      } else {
        setConfigState_Error("Error: Can't access your Teams settings")
      }
    } else {
      value.length > 0 ? setConfigState_Error(`Error: Incorrect token length.  ${value.length}/36`) : setConfigState_Inital()
    }
  };

  useEffect(() => {
    if (context) {
      microsoftTeams.settings.registerOnSaveHandler(
        (saveEvent: microsoftTeams.settings.SaveEvent):void => {
          if(tokenValue && companyId){
            const settings: ExtendedConnectorSettings = {
              entityId: createEntityId(tokenValue, companyId), 
              contentUrl: `https://${REACT_APP_PUBLIC_HOSTNAME}/index.html?name={loginHint}&tenant={tid}&group={groupId}&theme={theme}`,
              configName: context.channelName,
            };

            microsoftTeams.settings.setSettings(settings);

            microsoftTeams.settings.getSettings((setting: ExtendedConnectorSettings) => {
              saveConfig(context, setting)
                .then(() => {
                  saveEvent.notifySuccess()
                })
                .catch((err) => {
                  console.log(err);
                  saveEvent.notifyFailure(err.message);
                });
            });
          } else {
            setConfigState_Error('Error: Something went wrong.  Please try reentering your Meltwater token')
            saveEvent.notifyFailure()
          }
        }
      );

      microsoftTeams.settings.registerOnRemoveHandler(
        (removeEvent:microsoftTeams.settings.RemoveEvent):void => {
          microsoftTeams.settings.getSettings((setting: ExtendedConnectorSettings) => {
            deleteConfig(context, setting)
              .then(() => {
                removeEvent.notifySuccess();
              })
              .catch((err) => {
                console.log(err)
                removeEvent.notifyFailure(err);
              });
          });
        }
      );

      // INFO: Runs if connector has been previously configured - Allows user to remove connector
      microsoftTeams.settings.getSettings((setting: ExtendedConnectorSettings):void => {
        if (setting.entityId) {
          const {token} = splitEntityId(setting.entityId)
          setTokenValue(token);
          setConfigState_Disabled()
          microsoftTeams.settings.setValidityState(false);
        }
      });
    }
  }, [context, tokenValue, companyId, setConfigState_Disabled, setConfigState_Error]);

  return (
    <Provider theme={theme}>
      <TokenInput
        token={tokenValue}
        disabled={configState.disabled}
        loading={configState.loading}
        error={configState.error}
        valid={configState.valid}
        onChangeFn={onChangeFn}
      />
    </Provider>
  );
}

export default MeltwaterTeamsConnectorConfig;
